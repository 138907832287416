import React, { useState } from 'react';
import { H2, Modal, SecondaryButtonInline } from '../../components';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionFAQMaybe = props => {
  const { listing, onManageDisableScrolling, contactArtistComponent } = props;

  const [isModalOpen, setModalOpen] = useState(false);

  const { faqs } = listing?.attributes?.publicData || {};

  if (!faqs || faqs?.length === 0) return null;

  return (
    <div className={classNames(css.container, css.field, css.setListField)}>
      <H2 as="h2" className={css.subtitle}>
        <FormattedMessage id="ListingPage.faqTitle" />
      </H2>
      <SecondaryButtonInline
        type="button"
        className={css.faqBtn}
        onClick={() => setModalOpen(true)}
      >
        <FormattedMessage id="ListingPage.faqButton" />
      </SecondaryButtonInline>
      <Modal
        id="ListingPage.faqModal"
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        containerClassName={css.faqModal}
        contentClassName={css.faqModalContent}
        onManageDisableScrolling={onManageDisableScrolling}
        footer={contactArtistComponent}
      >
        <div className={css.faqTitle}>
          <FormattedMessage
            id="ListingPage.faqModalTitle"
            values={{
              listingTitle: (
                <span className={css.marketplaceColored}>{listing?.attributes?.title}</span>
              ),
            }}
          />
        </div>
        <div className={css.faqContainer}>
          {faqs?.map(({ title, answer }, index) => {
            return (
              <div key={index} className={css.faq}>
                <div className={css.question}>{`${index + 1}. ${title}`}</div>
                <pre className={css.answer}>{answer}</pre>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default SectionFAQMaybe;
