import classNames from 'classnames';
import React from 'react';
import { IconArtistPlus, IconLocation, IconMembers, IconReviewStar } from '../../components';
import { priceData } from './ListingPage.shared';
import { useConfiguration } from '../../context/configurationContext';
import { useIntl } from 'react-intl';
import { calculateTotalRating } from '../../util/listing';

import css from './ListingPage.module.css';

const SectionBasicInfo = props => {
  const { listing } = props;
  const config = useConfiguration();
  const intl = useIntl();

  const { price, publicData, metadata } = listing?.attributes || {};
  const { numOfBandMembers, location, addOns, maxNumOfBandMembers } = publicData || {};
  const { city } = location || {};
  const { averageRating, ratingCount, externalRatingCount, externalAverageRating } = metadata || {};
  const { formattedPrice } = priceData(price, config.currency, intl);
  const { totalRating, totalRatingCount } = calculateTotalRating(
    averageRating,
    ratingCount,
    externalRatingCount,
    externalAverageRating
  );

  return (
    <div className={classNames(css.basicInfo, css.field)}>
      <div className={css.cell}>
        <IconLocation className={css.icon} />
        <span className={css.text}>{city || '-'}</span>
      </div>
      <div className={css.cell}>
        <IconReviewStar isFilled className={css.icon} />
        <b>
          {intl.formatNumber(totalRating || 0, {
            maximumFractionDigits: 1,
          })}
        </b>
        <span className={css.text}> {`(${totalRatingCount || 0})`}</span>
      </div>
      <div className={css.cell}>
        <IconMembers className={css.icon} />
        <span className={css.text}>
          {intl.formatMessage(
            { id: 'ListingPage.numOfBandMembers' },
            {
              numOfBandMembers: maxNumOfBandMembers
                ? `${numOfBandMembers}-${maxNumOfBandMembers}`
                : numOfBandMembers,
            }
          )}
        </span>
      </div>
      <div className={css.cell}>
        <IconArtistPlus className={css.icon} />
        <span className={css.text}>
          {intl.formatMessage({ id: 'ListingPage.addOns' }, { numOfAddons: addOns?.length || 0 })}
        </span>
      </div>
      <div className={css.price}>
        <span className={css.text}>{intl.formatMessage({ id: 'General.from' })}:</span>
        <span className={css.priceValue}>{formattedPrice}</span>
      </div>
    </div>
  );
};

export default SectionBasicInfo;
