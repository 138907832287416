import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { H2, IconArrowHead, InlineTextButton } from '../../components';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';

const SectionDescription = props => {
  const { text, title, maxLines = 10 } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [canOverflow, setCanOverflow] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current && !isExpanded) {
        const isOverflowing = textRef.current.scrollHeight > textRef.current.clientHeight;
        setCanOverflow(isOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [text, maxLines, isExpanded]);

  return (
    <div className={classNames(css.field, css.container, css.description)}>
      {canOverflow && (
        <InlineTextButton className={css.expandBtn} onClick={() => setIsExpanded(prev => !prev)}>
          <IconArrowHead direction={isExpanded ? 'up' : 'down'} />
        </InlineTextButton>
      )}
      <H2 as="h2" className={css.subtitle}>
        {title}
      </H2>
      <pre
        ref={textRef}
        className={classNames(isExpanded ? css.expanded : css.collapsed)}
        style={{ '--max-lines': maxLines }}
      >
        {text}
      </pre>
      {canOverflow && (
        <>
          {isExpanded && ' '}
          <InlineTextButton
            type="button"
            className={classNames(css.overflowBtn, css.descriptionBtn)}
            onClick={() => setIsExpanded(prev => !prev)}
          >
            <FormattedMessage id={!isExpanded ? 'ListingPage.showMore' : 'ListingPage.showLess'} />
          </InlineTextButton>
        </>
      )}
    </div>
  );
};

export default SectionDescription;
