import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_CLOSED, LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureCurrentUser,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  Page,
  NamedRedirect,
  LayoutSingleColumn,
  Modal,
  StripeConnectAccountForm,
  StripeConnectAccountStatusBox,
  OrderPanel,
  H4,
  NamedLink,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  fetchTimeSlots,
  fetchTransactionLineItems,
  savePayoutDetails,
  requestPublishListingDraft,
  setInitialValues,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleSubmit,
  handleContactUser,
  handleSubmitInquiry,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionAddonsMaybe from './SectionAddonsMaybe.js';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';

import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction.js';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes.js';
import { requirePayoutDetails } from '../../util/configHelpers.js';
import {
  getStripeConnectAccountLink,
  stripeAccountClearError,
} from '../../ducks/stripeConnectAccount.duck.js';
import { tabCompleted } from '../../util/listing.js';
import { ADD_ON_SET_UP_OPTIONS, GALLERY, SUPPORTED_TABS } from '../../constants/listing.js';
import SectionTypesAndCategories from './SectionTypesAndCategories.js';
import SectionBasicInfo from './SectionBasicInfo.js';
import SectionTimings from './SectionTimings.js';
import SectionSetListMaybe from './SectionSetListMaybe.js';
import SectionAuthorMaybe from './SectionAuthorMaybe.js';
import SectionFAQMaybe from './SectionFAQMaybe.js';
import SectionDescription from './SectionDescription.js';

import { getSearchFiltersFromBookingFormValues } from '../../util/search.js';

import SectionReviews from './SectionReviews.js';

const { UUID, Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const STRIPE_ONBOARDING_RETURN_URL_TYPES = [
  STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
  STRIPE_ONBOARDING_RETURN_URL_FAILURE,
];

const getListingTypeConfig = (listing, config) => {
  const existingListingType = listing?.attributes?.publicData?.listingType;
  const validListingTypes = config.listing.listingTypes;
  const hasOnlyOneListingType = validListingTypes?.length === 1;

  const listingTypeConfig = existingListingType
    ? validListingTypes.find(conf => conf.listingType === existingListingType)
    : hasOnlyOneListingType
    ? validListingTypes[0]
    : null;
  return listingTypeConfig;
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'ListingStripeOnboardingPage',
    routes,
    { ...pathParams, variant: 'draft', returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

const RedirectToStripe = ({ redirectFn }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(redirectFn('custom_account_verification'), []);
  return <FormattedMessage id="EditListingWizard.redirectingToStripe" />;
};

export const ListingPageComponent = props => {
  const {
    history,
    routeConfiguration,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    config,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    createStripeAccountInProgress,
    getAccountLinkInProgress,
    getAccountLinkError,
    callSetInitialValues,
    onInitializeCardPaymentData,
    onPayoutDetailsChange,
    onGetStripeConnectAccountLink,

    onPayoutDetailsSubmit,
    onSendInquiry,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    sendInquiryInProgress,
    onPublishListingDraft,
    publishListingError,
    publishListingInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    isAuthenticated,
    txLineItems,
    depositLineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onFetchTransactionLineItems,
    orderData,
  } = props;

  const [showPayoutDetails, setShowPayoutDetails] = useState(false);
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const bookingFormRef = useRef();

  const listingConfig = config.listing;

  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const stripeFormDisabled = getAccountLinkInProgress;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!ensuredCurrentUser.id;
  const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

  const { isArtist } = currentUser?.attributes?.profile?.publicData || {};

  const rootURL = config.marketplaceRootURL;

  const { returnURLType, ...pathParams } = params;

  const successURL = createReturnURL(
    STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
    rootURL,
    routeConfiguration,
    pathParams
  );
  const failureURL = createReturnURL(
    STRIPE_ONBOARDING_RETURN_URL_FAILURE,
    rootURL,
    routeConfiguration,
    pathParams
  );

  const accountId = stripeConnected ? stripeAccount.id : null;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const savedCountry = stripeAccountData ? stripeAccountData.country : null;

  const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
    onGetStripeConnectAccountLink,
    {
      accountId,
      successURL,
      failureURL,
    }
  );

  const stripeAccountError =
    createStripeAccountError || updateStripeAccountError || fetchStripeAccountError;

  const stripeAccountLinkError = getAccountLinkError;

  const hasStripeOnboardingDataIfNeeded = returnURLType ? !!(currentUser && currentUser.id) : true;

  const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;

  const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
  const showVerificationNeeded = stripeConnected && requirementsMissing;

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = SUPPORTED_TABS[SUPPORTED_TABS.length - 1];

  const editParams = useMemo(
    () => ({
      id: listingId.uuid,
      slug: listingSlug,
      type: listingPathParamType,
      tab: listingTab,
    }),
    [listingId.uuid, listingPathParamType, listingSlug, listingTab]
  );

  const bookingData = useMemo(() => {
    const { listingId: orderListingId, order } = orderData || {};

    return {
      ...((orderListingId === listingId?.uuid && order) || {}),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId.uuid, orderData]);

  const setPageInitialValues = useCallback(
    (initialValues, pageName) => {
      const { setInitialValues } = findRouteByRouteName(pageName, routeConfiguration);

      callSetInitialValues(setInitialValues, initialValues);
    },
    [callSetInitialValues, routeConfiguration]
  );

  const onFormValuesChanged = values => {
    if (!bookingFormRef?.current?.getState()?.dirty) return;

    const initialValuesSearchPage = {
      searchFilters: getSearchFiltersFromBookingFormValues(values),
    };

    setPageInitialValues(initialValuesSearchPage, 'SearchPage');
  };

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  const commonParams = { params, history, routes: routeConfiguration };

  const onSubmit = handleSubmit({
    ...commonParams,
    intl,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const handlePublishListing = useCallback(() => {
    const listingTypeConfig = getListingTypeConfig(currentListing, config);
    // Through hosted configs (listingTypeConfig.defaultListingFields?.payoutDetails),
    // it's possible to publish listing without payout details set by provider.
    // Customers can't purchase these listings - but it gives operator opportunity to discuss with providers who fail to do so.
    const isPayoutDetailsRequired = requirePayoutDetails(listingTypeConfig);

    const stripeConnected = !!currentUser?.stripeAccount?.id;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const stripeRequirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const isLastRequiredStepCompleted = tabCompleted(GALLERY, currentListing);

    if (!isLastRequiredStepCompleted) {
      history.push(createResourceLocatorString('EditListingPage', routeConfiguration, editParams));

      return;
    }

    if (!isPayoutDetailsRequired || (stripeConnected && !stripeRequirementsMissing)) {
      onPublishListingDraft(currentListing.id)
        .then(() => {
          history.push(
            createResourceLocatorString('ListingPageVariant', routeConfiguration, {
              ...pathParams,
              variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
            })
          );
        })
        .catch(() => {});

      return;
    }

    setShowPayoutDetails(true);
  }, [
    config,
    currentListing,
    currentUser?.stripeAccount?.id,
    editParams,
    history,
    onPublishListingDraft,
    pathParams,
    routeConfiguration,
    stripeAccount,
  ]);

  const handlePayoutModalClose = useCallback(() => {
    setShowPayoutDetails(false);
  }, []);

  useEffect(() => {
    if (returnURLType != null && !showPayoutDetails) {
      setShowPayoutDetails(true);
    }
  }, [showPayoutDetails, returnURLType]);

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const { orderOpen } = parse(location.search);

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } // Redirect from success URL to basic path for StripePayoutPage
  else if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
    return <NamedRedirect name="ListingPageVariant" params={pathParams} />;
  }

  const { description = '', price = null, title = '', publicData = {} } = currentListing.attributes;

  const { bandType, bandCategory, setList, addOns: addOnsData, externalReviews } = publicData;

  const addOns = addOnsData?.map(addOn => ({
    ...addOn,
    price: new Money(addOn.price, config.currency),
  }));
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });

  const types = listingConfig.listingFields
    .find(field => field.key === 'bandType')
    .enumOptions.filter(option => bandType && bandType.includes(option.option));

  const categories = listingConfig.listingFields
    .find(field => field.key === 'bandCategory')
    .enumOptions.filter(option => bandCategory && bandCategory.includes(option.option));

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const transactionProcessAlias = publicData?.transactionProcessAlias;
  const processName = resolveLatestProcessName(transactionProcessAlias?.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);

  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
        price: convertMoneyToNumber(price),
        priceCurrency: price.currency,
      }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';
  const configsByKey = config.listing.listingFields.reduce(
    (configs, conf) => ({ ...configs, [conf.key]: conf }),
    {}
  );

  const standardPerformanceDurationOptions = configsByKey.standardPerformanceDuration.enumOptions;
  const estimatedSetUpDurationOptions = configsByKey.estimatedSetUpDuration.enumOptions;
  const standardBreakDurationOptions = configsByKey.standardBreakDuration.enumOptions;

  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div>
            {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              />
            ) : null}
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={editParams}
                handlePublishListing={handlePublishListing}
                publishListingError={publishListingError}
                publishListingInProgress={publishListingInProgress}
              />
            ) : null}
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
          </div>
        </div>
        <div className={css.contentWrapperForHeroLayout}>
          <div className={css.mainColumnForHeroLayout}>
            <SectionBasicInfo listing={currentListing} />
            <SectionTypesAndCategories
              types={types}
              categories={categories}
              orderOpen={orderOpen}
            />
            <SectionTimings
              standardBreakDurationOptions={standardBreakDurationOptions}
              standardPerformanceDurationOptions={standardPerformanceDurationOptions}
              estimatedSetUpDurationOptions={estimatedSetUpDurationOptions}
              listing={currentListing}
            />
            <SectionSetListMaybe
              onManageDisableScrolling={onManageDisableScrolling}
              setList={setList}
            />

            <SectionDescription
              title={
                <FormattedMessage id="ListingPage.descriptionTitle" values={{ title: richTitle }} />
              }
              text={description}
            />
            <SectionAddonsMaybe
              addOns={addOns}
              standardPerformanceDurationOptions={standardPerformanceDurationOptions}
              estimatedSetUpDurationOptions={ADD_ON_SET_UP_OPTIONS}
              standardBreakDurationOptions={standardBreakDurationOptions}
              onManageDisableScrolling={onManageDisableScrolling}
              intl={intl}
              bookingFormRef={bookingFormRef}
            />
            <SectionFAQMaybe
              listing={currentListing}
              onManageDisableScrolling={onManageDisableScrolling}
              contactArtistComponent={
                <SectionAuthorMaybe
                  title={title}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={onContactUser}
                  isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                  onCloseInquiryModal={() => setInquiryModalOpen(false)}
                  sendInquiryError={sendInquiryError}
                  sendInquiryInProgress={sendInquiryInProgress}
                  onSubmitInquiry={onSubmitInquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                  contactArtistDisabled={isOwnListing || isArtist}
                  contactArtistDisabledMessage={intl.formatMessage({
                    id: 'ListingPage.contactOtherArtistsDisabled',
                  })}
                />
              }
            />
            <SectionReviews
              defaultReviews={reviews || []}
              externalReviews={externalReviews || []}
              fetchReviewsError={fetchReviewsError}
            />
          </div>
          <div>
            <OrderPanel
              className={css.orderColumnForHeroLayout}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              initialValues={bookingData}
              title={<FormattedMessage id="ListingPage.buildAQuote" />}
              titleDesktop={
                <H4 as="h2" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.buildAQuote" />
                </H4>
              }
              payoutDetailsWarning={payoutDetailsWarning}
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              txLineItems={txLineItems}
              depositLineItems={depositLineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              bookingFormRef={bookingFormRef}
              onBookingFormChange={onFormValuesChanged}
              setInitialValues={setPageInitialValues}
            />
          </div>

          <Modal
            id="ListingPage.payoutModal"
            isOpen={showPayoutDetails && hasStripeOnboardingDataIfNeeded}
            onClose={handlePayoutModalClose}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
          >
            <div className={css.modalPayoutDetailsWrapper}>
              <div className={css.modalTitle}>
                <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
                <br />
                <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
              </div>
              {!currentUserLoaded ? (
                <FormattedMessage id="StripePayoutPage.loadingData" />
              ) : returnedAbnormallyFromStripe && !stripeAccountLinkError ? (
                <p className={css.modalMessage}>
                  <RedirectToStripe redirectFn={handleGetStripeConnectAccountLink} />
                </p>
              ) : (
                <>
                  <p className={css.modalMessage}>
                    <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                  </p>
                  <StripeConnectAccountForm
                    disabled={stripeFormDisabled}
                    inProgress={payoutDetailsSaveInProgress || createStripeAccountInProgress}
                    ready={payoutDetailsSaved}
                    currentUser={ensuredCurrentUser}
                    stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                    savedCountry={savedCountry}
                    submitButtonText={intl.formatMessage({
                      id: 'StripePayoutPage.submitButtonText',
                    })}
                    stripeAccountError={stripeAccountError}
                    stripeAccountFetched={stripeAccountFetched}
                    stripeAccountLinkError={stripeAccountLinkError}
                    onChange={onPayoutDetailsChange}
                    onSubmit={onPayoutDetailsSubmit}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                    stripeConnected={stripeConnected}
                  >
                    {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                      <StripeConnectAccountStatusBox
                        type="verificationNeeded"
                        inProgress={getAccountLinkInProgress}
                        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                          'custom_account_verification'
                        )}
                      />
                    ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                      <StripeConnectAccountStatusBox
                        type="verificationSuccess"
                        inProgress={getAccountLinkInProgress}
                        disabled={payoutDetailsSaveInProgress}
                        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                          'custom_account_update'
                        )}
                      />
                    ) : null}
                  </StripeConnectAccountForm>
                </>
              )}
            </div>
          </Modal>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,

  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
    returnURLType: oneOf(STRIPE_ONBOARDING_RETURN_URL_TYPES),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;

  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    txLineItems,
    depositLineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,

    payoutDetailsSaveInProgress,
    payoutDetailsSaved,

    publishListingError,
    publishListingInProgress,
    orderData,
  } = state.ListingPage;

  const { currentUser } = state.user;

  const {
    getAccountLinkInProgress,
    getAccountLinkError,
    createStripeAccountInProgress,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
  } = state.stripeConnectAccount;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    publishListingError,
    publishListingInProgress,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    createStripeAccountError,
    updateStripeAccountError,
    fetchStripeAccountError,
    stripeAccount,
    stripeAccountFetched,
    createStripeAccountInProgress,
    getAccountLinkInProgress,
    getAccountLinkError,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    txLineItems,
    depositLineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    orderData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onPayoutDetailsChange: () => dispatch(stripeAccountClearError()),
  onGetStripeConnectAccountLink: params => dispatch(getStripeConnectAccountLink(params)),
  onPayoutDetailsSubmit: (values, isUpdateCall) =>
    dispatch(savePayoutDetails(values, isUpdateCall)),
  onPublishListingDraft: listingId => dispatch(requestPublishListingDraft(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
