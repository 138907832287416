import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconArrowHead,
  ResponsiveImage,
  SecondaryButton,
  IconPlay,
  IconGallery,
  IconSpinner,
  H1,
} from '../../../components';
import { YoutubeEmbed } from '../../PageBuilder/Primitives/YoutubeEmbed';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ListingImageGallery.module.css';

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
};
const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : hasDimensions
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

const ListingImageGallery = props => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFullscreenVideo, setIsFullscreenVideo] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const imageGalleryRef = useRef();
  const videoGalleryRef = useRef();

  const { intl, rootClassName, className, images, imageVariants, videoLinks, listingTitle } = props;

  const imgs = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
      index: i,
      type: 'image',
    };
  });
  const videos = useMemo(
    () =>
      videoLinks
        ? videoLinks.map((videoLink, i) => ({
            original: 'abc',
            link: videoLink,
            type: 'video',
            alt: intl.formatMessage(
              { id: 'ListingImageGallery.videoAltText' },
              { index: i + 1, count: images.length }
            ),
            index: i,
          }))
        : [],
    [images.length, intl, videoLinks]
  );

  const items = imgs;

  // imageVariants are scaled variants.
  const { aspectWidth, aspectHeight } =
    !videoLinks || videoLinks.length === 0
      ? getFirstImageAspectRatio(images?.[0], imageVariants[0])
      : { aspectWidth: 16, aspectHeight: 9 };

  const imageSizesMaybe = isFullscreen
    ? {}
    : { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };

  const renderVideo = item => {
    return (
      <div
        width={aspectWidth || 1}
        height={aspectHeight || 1}
        className={isFullscreenVideo ? css.itemWrapperFullscreen : css.itemWrapper}
        key={item?.link}
      >
        {isFullscreenVideo && item.index === currentVideoIndex && (
          <YoutubeEmbed key={item?.link} url={item?.link} disableFullscreen />
        )}
      </div>
    );
  };

  const renderItem = image => {
    const item = isFullscreen ? image : items?.[0];

    return isFullscreen ? (
      <AspectRatioWrapper
        width={aspectWidth || 1}
        height={aspectHeight || 1}
        key={item?.image?.id?.uuid}
        className={css.itemWrapperFullscreen}
      >
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.itemFullscreen}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
            {...imageSizesMaybe}
          />
        </div>
      </AspectRatioWrapper>
    ) : (
      <div className={css.itemWrapper}>
        <AspectRatioWrapper
          width={aspectWidth || 1}
          height={aspectHeight || 1}
          key={item?.image?.id?.uuid}
          className={css.imgWrapper}
        >
          <div className={css.itemCentering}>
            <ResponsiveImage
              rootClassName={css.item}
              image={item.image}
              alt={item.alt}
              variants={imageVariants}
              {...imageSizesMaybe}
            />
          </div>

          <div className={css.overlay}>
            <H1 className={css.listingTitle}>{listingTitle}</H1>
            <div className={css.buttons}>
              {videos?.length !== 0 && (
                <Button
                  type="button"
                  className={css.videoBtn}
                  onClick={() => {
                    videoGalleryRef?.current?.toggleFullScreen();
                  }}
                >
                  <IconPlay />
                  <FormattedMessage id="Gallery.watchVideo" />
                </Button>
              )}
              <SecondaryButton
                type="button"
                className={css.galleryBtn}
                onClick={() => {
                  imageGalleryRef?.current?.toggleFullScreen();
                }}
              >
                <IconGallery />
                <FormattedMessage id="Gallery.viewGallery" />
              </SecondaryButton>
            </div>
          </div>
        </AspectRatioWrapper>
      </div>
    );
  };
  const onScreenChange = isFull => {
    setIsFullscreen(isFull);
  };

  const onScreenChangeVideo = isFull => {
    setIsFullscreenVideo(isFull);
  };

  const renderLeftNav = (onClick, disabled, fullScreen) => {
    if (!fullScreen) return null;

    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled, fullScreen) => {
    if (!fullScreen) return null;

    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };
  const renderFullscreenButton = (onClick, isFullscreen) => {
    return isFullscreen ? (
      <Button
        onClick={onClick}
        rootClassName={css.close}
        title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
      >
        <span className={css.closeText}>
          <FormattedMessage id="ListingImageGallery.closeModal" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    ) : null;
  };

  if (items.length === 0) {
    return (
      <ResponsiveImage
        noImageIcon={<IconSpinner />}
        noImageMessage={intl.formatMessage({ id: 'General.loading' })}
        className={css.noImage}
        image={null}
        variants={[]}
        alt=""
      />
    );
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <ReactImageGallery
        additionalClass={classNames(classes, { [css.hidden]: !isFullscreenVideo })}
        items={videos}
        renderItem={renderVideo}
        onScreenChange={onScreenChangeVideo}
        showIndex={isFullscreenVideo ? true : false}
        ref={videoGalleryRef}
        renderLeftNav={(onClick, disabled) => renderLeftNav(onClick, disabled, isFullscreenVideo)}
        renderRightNav={(onClick, disabled) => renderRightNav(onClick, disabled, isFullscreenVideo)}
        renderFullscreenButton={renderFullscreenButton}
        {...IMAGE_GALLERY_OPTIONS}
        disableKeyDown={!isFullscreenVideo}
        onSlide={index => setCurrentVideoIndex(index)}
      />
      <ReactImageGallery
        additionalClass={classes}
        items={items}
        renderItem={renderItem}
        onScreenChange={onScreenChange}
        renderLeftNav={(onClick, disabled) => renderLeftNav(onClick, disabled, isFullscreen)}
        renderRightNav={(onClick, disabled) => renderRightNav(onClick, disabled, isFullscreen)}
        renderFullscreenButton={renderFullscreenButton}
        showIndex={isFullscreen ? true : false}
        ref={imageGalleryRef}
        {...IMAGE_GALLERY_OPTIONS}
        disableKeyDown={!isFullscreen}
      />
    </>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  videoLinks: arrayOf(string),
  imageVariants: arrayOf(string).isRequired,
  thumbnailVariants: arrayOf(string),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
