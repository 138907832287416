import React from 'react';
import { FormattedMessage } from 'react-intl';
import { H2 } from '../../components';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionTimings = props => {
  const {
    listing,
    standardBreakDurationOptions,
    standardPerformanceDurationOptions,
    estimatedSetUpDurationOptions,
  } = props;
  const { publicData } = listing?.attributes || {};
  const {
    standardPerformanceStartTime,
    standardBreakDuration,
    standardPerformanceDuration,
    estimatedSetUpDuration,
  } = publicData || {};

  return (
    <div className={classNames(css.field, css.container)}>
      <H2 as="h2" className={css.subtitle}>
        <FormattedMessage id="ListingPage.timingTitle" />
      </H2>
      <p className={css.timesContainer}>
        <span className={css.timesField}>
          {
            standardPerformanceDurationOptions?.find(
              option => option.option === standardPerformanceDuration
            )?.label
          }
          <label className={css.timesLabel}>
            <FormattedMessage id="ListingPage.standardPerformanceDurationLabel" />
          </label>
        </span>
        <span className={css.timesField}>
          {standardPerformanceStartTime}
          <label className={css.timesLabel}>
            <FormattedMessage id="ListingPage.standardPerformanceStartTimeLabel" />
          </label>
        </span>
        <span className={css.timesField}>
          {
            estimatedSetUpDurationOptions?.find(option => option.option === estimatedSetUpDuration)
              ?.label
          }
          <label className={css.timesLabel}>
            <FormattedMessage id="ListingPage.estimatedSetUpTimeDurationLabel" />
          </label>
        </span>
        <span className={css.timesField}>
          {
            standardBreakDurationOptions?.find(option => option.option === standardBreakDuration)
              ?.label
          }
          <label className={css.timesLabel}>
            <FormattedMessage id="ListingPage.standardBreakDurationLabel" />
          </label>
        </span>
      </p>
    </div>
  );
};

export default SectionTimings;
