import React, { useState } from 'react';
import { AddOn, Button, H2, InlineTextButton } from '../../components';
import { FormattedMessage } from 'react-intl';
import AddOnModal from './AddOnModal/AddOnModal';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionAddonsMaybe = props => {
  const {
    addOns,
    intl,
    onManageDisableScrolling,
    bookingFormRef,
    standardPerformanceDurationOptions,
    estimatedSetUpDurationOptions,
    standardBreakDurationOptions,
  } = props;

  const [isAddOnModalOpen, setAddOnModalOpen] = useState(false);
  const [selectedAddOnIndex, setSelectedAddOnIndex] = useState(-1);

  return addOns?.length > 0 ? (
    <div className={classNames(css.field, css.addons)}>
      <div className={css.addOnsHeader}>
        <H2 as="h2" className={classNames(css.subtitle, css.addOnTitle)}>
          <FormattedMessage id="ListingPage.addOnTitle" values={{ total: addOns.length }} />
        </H2>

        {addOns?.length > 6 && (
          <InlineTextButton
            type="button"
            className={css.viewAddonsBtn}
            onClick={() => {
              setSelectedAddOnIndex(0);
              setAddOnModalOpen(true);
            }}
          >
            {intl.formatMessage({ id: 'ListingPage.viewAllAddons' })}
          </InlineTextButton>
        )}
      </div>
      <div className={css.addOnsContainer}>
        {addOns.slice(0, 6).map((addOn, index) => (
          <Button
            className={css.addOnBtn}
            key={addOn?.title}
            type="button"
            onClick={() => {
              setSelectedAddOnIndex(index);
              setAddOnModalOpen(true);
            }}
          >
            <AddOn className={css.addOn} addOn={addOn} />
          </Button>
        ))}

        <AddOnModal
          id="ListingPage.addOnModal"
          isOpen={isAddOnModalOpen}
          onClose={() => setAddOnModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          addOns={addOns}
          intl={intl}
          selectedIndex={selectedAddOnIndex}
          setSelectedIndex={setSelectedAddOnIndex}
          standardPerformanceDurationOptions={standardPerformanceDurationOptions}
          estimatedSetUpDurationOptions={estimatedSetUpDurationOptions}
          standardBreakDurationOptions={standardBreakDurationOptions}
          bookingFormRef={bookingFormRef}
        />
      </div>
    </div>
  ) : null;
};

export default SectionAddonsMaybe;
