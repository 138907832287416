import React, { useEffect, useRef, useState } from 'react';
import { H2, IconArrowHead, InlineTextButton } from '../../components';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const SectionTypesAndCategories = props => {
  const { types, categories } = props;

  const [contentExpanded, setContentExpanded] = useState(false);

  const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const cells = ref.current?.children;
      if (cells && cells.length > 0) {
        const startingOffset = cells[0].offsetTop;
        let numItems = 0;
        for (let i = 0; i < cells.length; i++) {
          if (cells[i].offsetTop > startingOffset) {
            break;
          }
          numItems++;
        }
        setNumberOfItemsDisplayed(numItems);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <div className={classNames(css.field, css.container)}>
      {(contentExpanded || numberOfItemsDisplayed !== categories?.length) && (
        <InlineTextButton
          className={css.expandBtn}
          onClick={() => setContentExpanded(prev => !prev)}
        >
          <IconArrowHead direction={contentExpanded ? 'up' : 'down'} />
        </InlineTextButton>
      )}
      <H2 as="h2" className={classNames(css.subtitle, css.categoriesTitle)}>
        <FormattedMessage id="ListingPage.typesAndCategoriesTitle" />
      </H2>
      <div className={css.types}>
        {types?.map(type => (
          <div key={type.option} className={css.type}>
            {type.icon}
            <span>{type.label}</span>
          </div>
        ))}
      </div>
      <div className={css.categoriesWrapper}>
        <div
          className={classNames(css.categories, {
            [css.categoriesLimitedHeight]:
              !contentExpanded && numberOfItemsDisplayed !== categories?.length,
          })}
          ref={ref}
        >
          {categories.map((category, index) => (
            <div
              className={classNames(css.category, {
                [css.hidden]: !contentExpanded && index >= numberOfItemsDisplayed,
              })}
              key={category.option}
            >
              <span>{category.label}</span>
            </div>
          ))}
        </div>

        <InlineTextButton
          type="button"
          className={classNames(css.overflowBtn, {
            [css.hidden]: contentExpanded || numberOfItemsDisplayed === categories?.length,
          })}
          onClick={() => setContentExpanded(prev => !prev)}
        >
          <FormattedMessage
            id="ListingPage.showMoreItems"
            values={{ num: categories?.length - numberOfItemsDisplayed }}
          />
        </InlineTextButton>
      </div>
    </div>
  );
};

export default SectionTypesAndCategories;
