import classNames from 'classnames';
import React, { useState } from 'react';
import { H2, IconNote, Modal, SecondaryButton } from '../../components';
import { FormattedMessage } from 'react-intl';

import css from './ListingPage.module.css';

const SectionSetListMaybe = props => {
  const { onManageDisableScrolling, setList } = props;
  const [isSetListModalOpen, setSetListModalOpen] = useState(false);

  return setList ? (
    <div className={classNames(css.container, css.field, css.setListField)}>
      <H2 as="h2" className={css.subtitle}>
        <FormattedMessage id="ListingPage.setListTitle" />
      </H2>
      <SecondaryButton
        className={css.setListBtn}
        type="button"
        onClick={() => setSetListModalOpen(true)}
      >
        <IconNote className={css.icon} />
        <FormattedMessage id="ListingPage.setListBtnLabel" />
      </SecondaryButton>
      <Modal
        id="ListingPage.setListModal"
        isOpen={isSetListModalOpen}
        onClose={() => {
          setSetListModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <span className={css.setListTitle}>
          <FormattedMessage id="ListingPage.setListTitle" />
        </span>

        <pre className={css.setList}>{setList}</pre>
      </Modal>
    </div>
  ) : null;
};

export default SectionSetListMaybe;
