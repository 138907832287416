import { array, bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { H2, H3, Modal, PrimaryButton, SecondaryButton } from '../../../components';
import { YoutubeEmbed } from '../../PageBuilder/Primitives/YoutubeEmbed';
import { ADD_ONS_OPTIONS } from '../../../constants/listing';
import { FormattedMessage } from 'react-intl';
import { intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { ADD_ON_ICON_OPTIONS } from '../../../util/listing';

import css from './AddOnModal.module.css';
import classNames from 'classnames';

const AddOnPanel = props => {
  const {
    addOn,
    intl,
    standardBreakDurationOptions,
    standardPerformanceDurationOptions,
    estimatedSetUpDurationOptions,
    bookingFormRef,
  } = props;

  const { _id, category, extraNumOfMembers, coreNumOfMembers } = addOn || {};

  const addOnKey = `${category}.${_id}`;
  const selectedAddOns = bookingFormRef.current?.getFieldState('formAddOns')?.value;
  const numOfAddOnMembers =
    (parseInt(extraNumOfMembers, 10) || 0) + (parseInt(coreNumOfMembers, 10) || 0);

  const [addOnSelected, setAddOnSelected] = useState(false);

  const addAddOn = value => {
    bookingFormRef.current
      ?.getFieldState('formAddOns')
      .change(selectedAddOns ? [...selectedAddOns, value] : [value]);
    setAddOnSelected(true);
  };

  const removeAddOn = value => {
    bookingFormRef.current
      ?.getFieldState('formAddOns')
      .change(selectedAddOns?.filter(addOn => addOn !== value));
    setAddOnSelected(false);
  };

  const isAddOnDisabled = addOn => {
    const [optionCategory, optionId] = addOn.split('.');
    const selectedAddOns = bookingFormRef.current?.getFieldState('formAddOns')?.value;

    return selectedAddOns?.some(selectedAddon => {
      const [category, _id] = selectedAddon.split('.');

      // If its the same category disable the field,
      // but don't disable if its the checked field
      return category === optionCategory && _id !== optionId;
    });
  };

  useEffect(() => {
    setAddOnSelected(selectedAddOns?.includes(addOnKey));
  }, [addOnKey, selectedAddOns]);

  return (
    <div className={css.panel}>
      <YoutubeEmbed className={css.video} url={addOn?.ytLinks?.[0]} />
      <div className={css.header}>
        <H3 as="h3" className={css.addOnTitle}>
          {addOn.title}
        </H3>
        <span className={css.price}>
          {intl.formatMessage({ id: 'AddOnModal.priceBtnLabel' })}
          <span className={css.priceAmount}>{formatMoney(intl, addOn?.price)}</span>
        </span>
      </div>
      <p className={classNames(css.description, css.text)}>{addOn?.description}</p>

      <p className={classNames(css.detailsWrapper, css.text)}>
        <span className={css.details}>
          <label className={css.label}>
            {intl.formatMessage({ id: 'AddOnModal.categoryLabel' })}
          </label>
          <FormattedMessage
            id={ADD_ONS_OPTIONS.find(option => option.value === addOn.category).label}
          />
        </span>
        <span className={css.details}>
          <label className={css.label}>
            {intl.formatMessage({ id: 'AddOnModal.numOfBandMembersLabel' })}
          </label>
          {numOfAddOnMembers}
        </span>
        <span className={css.details}>
          <label className={css.label}>
            {intl.formatMessage({ id: 'AddOnModal.standardPerformanceDurationLabel' })}
          </label>
          {
            standardPerformanceDurationOptions.find(
              option => option.option === addOn.standardPerformanceDuration
            ).label
          }
        </span>
        <span className={css.details}>
          <label className={css.label}>
            {intl.formatMessage({ id: 'AddOnModal.estimatedSetUpDurationLabel' })}
          </label>
          {
            estimatedSetUpDurationOptions.find(
              option => option.value === addOn.estimatedSetUpDuration
            )?.label
          }
        </span>
        <span className={css.details}>
          <label className={css.label}>
            {intl.formatMessage({ id: 'AddOnModal.standardBreakDurationLabel' })}
          </label>

          {
            standardBreakDurationOptions.find(
              option => option.option === addOn.standardBreakDuration
            ).label
          }
        </span>
      </p>

      <PrimaryButton
        type="button"
        className={css.btn}
        disabled={isAddOnDisabled(addOnKey)}
        onClick={() => (addOnSelected ? removeAddOn(addOnKey) : addAddOn(addOnKey))}
      >
        {addOnSelected
          ? intl.formatMessage({ id: 'AddOnModal.removeFromQuoteBtn' })
          : intl.formatMessage({ id: 'AddOnModal.addToQuoteBtn' })}
      </PrimaryButton>
    </div>
  );
};

const AddOnModal = props => {
  const {
    isOpen,
    onClose,
    onManageDisableScrolling,
    addOns,
    intl,
    id,
    selectedIndex,
    setSelectedIndex,
    bookingFormRef,
    ...rest
  } = props;

  if (selectedIndex < 0) return null;

  const getImage = iconName => ADD_ON_ICON_OPTIONS.find(opt => opt.value === iconName)?.image;

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      containerClassName={css.modal}
    >
      <div className={css.root}>
        <aside className={css.aside}>
          <H2 as="h2" className={css.title}>
            <FormattedMessage id="AddOnModal.title" values={{ total: addOns?.length }} />
          </H2>
          <div className={css.addOnList}>
            {addOns.map((addOn, index) => (
              <SecondaryButton
                className={classNames(css.addOnBtn, { [css.selected]: index === selectedIndex })}
                key={addOn.title}
                type="button"
                onClick={() => setSelectedIndex(index)}
              >
                <img src={getImage(addOn?.iconName)} className={css.icon} alt="" />
                <span className={css.btnText}>
                  <span className={css.btnTitle}>{addOn?.title}</span>
                  <span className={css.btnCategory}>
                    {intl.formatMessage({
                      id: ADD_ONS_OPTIONS.find(option => option.value === addOn.category).label,
                    })}
                  </span>
                </span>
              </SecondaryButton>
            ))}
          </div>
          <select
            className={css.addOnSelect}
            value={selectedIndex}
            onChange={e => setSelectedIndex(e.target.value)}
          >
            {addOns.map((addOn, index) => (
              <option key={addOn.title} value={index}>
                {addOn.title}
              </option>
            ))}
          </select>
        </aside>

        <AddOnPanel
          key={addOns[selectedIndex].title}
          addOn={addOns[selectedIndex]}
          intl={intl}
          bookingFormRef={bookingFormRef}
          {...rest}
        />
      </div>
    </Modal>
  );
};

AddOnModal.propTypes = {
  id: string,
  isOpen: bool,
  onClose: func,
  onManageDisableScrolling: func,
  addOns: array,
  selectedIndex: number,
  setSelectedIndex: func,
  bookingFormRef: object,
  // from useIntl
  intl: intlShape.isRequired,
};

export default AddOnModal;
